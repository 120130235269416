import React from 'react';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FcGoogle } from "react-icons/fc";
import RegistrationForm from '../RegistrationForm/RegistrationForm';


const Signup = () => {
  return (
    <Container fluid className="login-container" style={{ height: '100vh', background: 'white', paddingTop: '10px', fontFamily: 'sans-serif' }}>
    <Row className="align-items-center" style={{ paddingLeft: '10px' }}>
      {/* Left Section */}
      <Col md={6} className="" style={{ background: 'rgba(242, 240, 254, 1)', height: '100%', borderRadius: '10px', boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)' }}>
        <Container fluid style={{ paddingTop: '100px' }}>
          <Row className="align-items-center">
            <Col md={6} style={{ display: 'flex', position: 'relative', paddingLeft: '4%' }}>
              <div style={{ position: 'absolute', left: '34%', top: '45%', transform: 'translateY(-50%)', zIndex: '1' }}>
                <h1 style={{ color: 'rgba(150, 82, 194, 1)', fontSize: '42px', fontWeight: '800', marginBottom: '0' }}>Content Genie</h1>
                <p style={{ color: 'rgba(150, 82, 194, 1)', fontSize: '18px', fontWeight: '600' }}>Teachers Handbook</p>
              </div>
              <div style={{ width: '200px', height: '200px', backgroundColor: 'rgba(194, 130, 250, 0.3)', borderRadius: '50%', position: 'relative' }}></div>
            </Col>
            <img src='/images/login_image_main.svg' alt="" style={{ padding: '10%', display: 'flex', width: '90%', imageRendering: 'auto' }} />
          </Row>
        </Container>
      </Col>

        {/* Right Section */}
        <Col md={6} className="login-right" style={{display:'flex',paddingLeft:'3%',paddingBottom:'35%',background:'',width:'45%',fontFamily:'sans-serif'}}>
        
          <RegistrationForm />
          
        </Col>
        
      </Row>
    </Container>
  );
};

export default Signup;