import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { FaAngleDown } from 'react-icons/fa';
import { MdHistory } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import './Leftsidemenu.css'
import { ActivityContext } from '../contexts/ActivityContext';
import { baseEndpoint } from '../APIs/endpoints';

const Leftsidemenu = () => {
    const [activityData, setActivityData] = useState([]); // State to store activities
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [expanded, setExpanded] = useState(false);
    const { updateActivity } = useContext(ActivityContext);
    const navigate = useNavigate();

    // Helper function to calculate the difference in days between two dates
    const getDayDifference = (date1, date2) => {
        const oneDay = 24 * 60 * 60 * 1000; // Hours*Minutes*Seconds*Milliseconds
        return Math.round((date1 - date2) / oneDay);
    };

    const handleActivityClick = (activity) => {
        
        const targetRoute = activity.content_type === 'MCQ' ? '/mcq/' : '/assignment/';
         
        navigate(targetRoute);
        // Store the selected activity's details in local storage
        localStorage.setItem('selectedActivity', JSON.stringify(activity));
        
        updateActivity(activity.response); // Store response data in context

        // Determine the target route based on a condition
    
        // Update activity using
    };
    
    

    // Helper function to get the start of the week (Sunday)
    const getStartOfWeek = (date) => {
        const resultDate = new Date(date);
        const day = resultDate.getDay();
        const diff = resultDate.getDate() - day; // Adjust when the day is Sunday
        return new Date(resultDate.setDate(diff));
    };

    // Helper function to categorize date into 'Today', 'Yesterday', 'This Week', 'Last Week', 'Last Month'
    const categorizeDate = (activityDate) => {
        const today = new Date();
        const startOfToday = new Date(today.setHours(0, 0, 0, 0));
        const startOfYesterday = new Date(startOfToday);
        startOfYesterday.setDate(startOfToday.getDate() - 1);
        const startOfWeek = getStartOfWeek(startOfToday);
        const startOfLastWeek = new Date(startOfWeek);
        startOfLastWeek.setDate(startOfWeek.getDate() - 7);
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

        const dateDifference = getDayDifference(new Date(startOfToday), new Date(activityDate));

        if (dateDifference === 0) return 'Today';
        if (dateDifference === 1) return 'Yesterday';
        if (new Date(activityDate) >= startOfWeek) return 'This Week';
        if (new Date(activityDate) >= startOfLastWeek) return 'Last Week';
        if (new Date(activityDate) >= startOfMonth) return 'This Month';
        if (new Date(activityDate) >= startOfLastMonth) return 'Last Month';
        return 'Older';
    };
    const [lineHeight, setLineHeight] = useState(0);
    const contentRef = useRef(null);

    // Function to update the line height dynamically
    const updateLineHeight = () => {
        if (contentRef.current) {
            setLineHeight(contentRef.current.scrollHeight);
        }
    };

    // Update the line height when the component mounts or when content changes
    useEffect(() => {
        updateLineHeight();
        window.addEventListener('resize', updateLineHeight);
        return () => window.removeEventListener('resize', updateLineHeight);
    }, [activityData]);

    // API call to fetch activity data from the backend
    useEffect(() => {
        const fetchActivityData = async () => {
            try {
                const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'))
                const user_id = userDetail?.id;

                // const response = await fetch(`http://localhost:8080/user_access/users/${user_id}/response`); // Replace with your backend port
                const response = await fetch(`${baseEndpoint}/user_access/users/${user_id}/response`)
                if (!response.ok) {
                    console.warn('No activity detected');
                    setActivityData([]);
                    setLoading(false);
                    return;
                }
    
                const data = await response.json();
    
                // Sort activities by createdDateTime in descending order
                const sortedActivities = data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
    
                // Group activities by categorized date
                const groupedByCategory = sortedActivities.reduce((acc, activity) => {
                    const category = categorizeDate(activity.createdDateTime.split('T')[0]);
                    if (!acc[category]) {
                        acc[category] = [];
                    }
                    acc[category].push(activity);
                    return acc; 
                }, {});
    
                // Convert grouped activities into an array and sort by custom order
                const categoryOrder = ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Older'];
                const sortedData = categoryOrder
                    .filter(category => groupedByCategory[category]) // Filter out categories with no activities
                    .map(category => ({ category, activities: groupedByCategory[category] }));
    
                setActivityData(sortedData);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching activity data:', err.message);
                setActivityData([]);
                setLoading(false);
            }
        };
    
        // Fetch activity data on component mount
        fetchActivityData();
    
        // Set up an interval to refresh the activity data every 20 seconds
        const intervalId = setInterval(fetchActivityData, 20000); // 20 seconds
    
        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    // Handle accordion toggle
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Format time function
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <Card className='mt-4 p-4' style={{ boxShadow: '0px 2px 9px 0px rgba(128, 128, 128, 0.5)', borderRadius:'10px', }}>
            <div className='d-flex align-items-center justify-content-around'>
                <h4 style={{color:'#3E325C'}}>Recent Activity</h4>
                <MdHistory style={{ fontSize: '20px',color:'#151D48' }} />
            </div>

            {activityData.length > 0 ? (
                activityData.map((categoryData, index) => (
                    <Accordion
                        key={categoryData.category}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            position: 'relative',
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<FaAngleDown />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            style={{
                                border: 'none',
                                borderBottom: 'none',
                                boxShadow: 'none',
                                position: 'relative',
                                padding: '8px 16px',
                                margin: 0,
                                height: '5vh',
                                color:'#3E325C'
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: '8px',
                                    backgroundColor: '#151D48',
                                    zIndex: 1,
                                    borderTopLeftRadius: expanded === `panel${index}` ? '8px' : '0',
                                    borderTopRightRadius: expanded === `panel${index}` ? '8px' : '0',
                                    borderBottomLeftRadius: expanded === `panel${index}` ? '8px' : '0',
                                    borderBottomRightRadius: expanded === `panel${index}` ? '8px' : '0',
                                }}
                            />
                             <Typography>{categoryData.category}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                        style={{
                                            position: 'relative',
                                            paddingLeft: '20px',
                                            margin: 0,
                                            overflowY: 'scroll',
                                            height: '20vh', // Adjust to match the desired scrollable height
                                        }}
                                        ref={contentRef}
                                        onScroll={updateLineHeight} // Update line height when scrolling
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                marginLeft: '4px',
                                                left: 0,
                                                top: 0,
                                                width: '2px',
                                                borderLeft: '2px dashed #000',
                                                height: `${lineHeight}px`, // Use dynamic height for the dotted line
                                            }}
                                        />
                                        <div style={{ paddingLeft: '8px' }}>
                                            {categoryData.activities.map((activity, idx) => (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleActivityClick(activity)}
                                                    style={{ marginBottom: '10px', cursor: 'pointer',color:'#3E325C'}}
                                                >
                                                    <Typography style={{fontSize:'14px'}}>
                                                        {activity.standard} {activity.title.charAt(0).toUpperCase() + activity.title.slice(1)} - {activity.content_type} 
                                                    </Typography>
                                                    <Typography style={{color:'#818184',fontSize:'14px'}}>{formatTime(activity.createdDateTime)}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>

                                </Accordion>


                                    ))
                                ) : (
                                    <Typography style={{textAlign:'center',color:'#3E325C'}}>No Activity Found</Typography>
                                )}
                            </Card>
    );
};

export default Leftsidemenu;
