import React, { useEffect, useState } from "react";
import { FaRegBell, FaAngleDown } from "react-icons/fa";
import './Header.css'
import { Button, Card, CardActionArea, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import { baseEndpoint } from "../APIs/endpoints";
const Header =()=>{

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // var [ userdata, setuserdata]=useState('')
    // var data  = localStorage.getItem('userdetail')
    // if(data){
    //   console.log("datat okkkk")
    //   setuserdata = JSON.parse(localStorage.getItem('userdetail'));
    // }else{
    //   console.log("datat not okkkk")
    //   setuserdata = JSON.parse(localStorage.getItem('userdata'))
    // }
    // console.log(userdata)
    const [userDetail, setUserDetail] = useState(null);
    const [googleuserdata,setgoogleuserdata]=useState(null);

    const [namevalue,setnamevalue]=useState('')

    useEffect(() => {
        // Retrieve user details from local storage or other source
        const storedUserDetail = localStorage.getItem('userdetail');
        
        if (storedUserDetail) {
            setUserDetail(JSON.parse(storedUserDetail));
            setnamevalue('ok')
        }else{
            setgoogleuserdata(JSON.parse(localStorage.getItem('userdata')))
        }
    }, []);

    const userName = namevalue === 'ok'
        ? `${userDetail?.first_name || ''} ${userDetail?.last_name || ''}`
        : `${googleuserdata?.first_name || ''} ${googleuserdata?.last_name || ''}`;

    console.log(userDetail,'yesssssssssssssssssssssssssssssssssss')

const navigate = useNavigate();

const LogoutMethod = async (e) => {
    e.preventDefault();
    // const endpoint = 'http://localhost:8080/userauth/logout/';
    var endpoint = '';

    var method = '';
    var tokenType ='';
    var AccessToken = '';

    if(localStorage.getItem('access_token')){
      console.log("it is for userlogout processs")
      // aws endpoint 
      endpoint = `${baseEndpoint}/userauth/logout` 
      // endpoint = 'http://3.110.204.201:8080/userauth/logout/';
      method = 'POST';
      tokenType = localStorage.getItem('token_type');
      AccessToken = localStorage.getItem('access_token');
    }else{
      console.log("it is for Googlelogout processs")
      // aws endpoint
      endpoint = `${baseEndpoint}/googleauth/google/logout`
      // endpoint = 'http://3.110.204.201:8080/googleauth/google/logout/';
      method = 'GET';
      tokenType = 'Token';
      AccessToken = localStorage.getItem('token');
      console.log(AccessToken)
      

    }
  
    console.log(tokenType,"qqqq",AccessToken,"it is seeted value")
    axios({
      method: method,
      url: endpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Authorization":`${tokenType} ${AccessToken}`,
        
      },  
    }).then(response => {
      console.log(response,"it is form response");

      if (response.status === 200 || response.data.msg ==="Token expired or revoked") {
        localStorage.clear()
        navigate('/login');
      } else {
        console.error("Login failed with status:", response.status);
      }
      // Handle successful response
    }).catch(error => {
      if (error.response.data.msg ==="Token expired or revoked") {
        localStorage.clear()
        navigate('/login');
      }
      console.error("There was an error!", error.response);
    });
  };
  const accessToken = localStorage.getItem('access_token');
  const googleToken = localStorage.getItem('token');

  // Condition to check if user is logged in (has access token or google token)
  const isLoggedIn = accessToken || googleToken;
    return(

        <div className=' admin-bar-container'>
            <div className='selected-section-title'>
                Content Genie
            </div>
            <div className='admin-right-section'>
                <div className='notification-icon'>
                    <FaRegBell />
                </div>
                {isLoggedIn ? (
                  <div className='user-section' style={{ display: 'flex' }}>
                      <div className='user-image'>
                          <img src='/images/Student.png' alt='user-image' />
                      </div>
                      <div className='user-details'>
                          <div className='user-name'>
                              {userName}
                          </div>
                          <div className='user-type'>Admin</div>
                      </div>
                      <div>
                          <Button
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleClick}
                          >
                              <FaAngleDown />
                          </Button>
                          <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                              }}
                          >
                              <MenuItem onClick={handleClose}>Profile</MenuItem>
                              <MenuItem onClick={handleClose}>My account</MenuItem>
                              <MenuItem onClick={LogoutMethod}>Logout</MenuItem>
                          </Menu>
                      </div>
                  </div>
              ) : (
                  <Button onClick={() => navigate('/login')} style={{ marginLeft: 'auto' }}>
                      Login
                  </Button>
              )}
            </div>
        </div>
    )
}
export default Header;