
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseEndpoint } from "../APIs/endpoints";



  const Googledata =()=>{
    const navigate = useNavigate();
    const userHandler = async ()=>{
        // console.log("userHanderler")
        const query = window.location.search;
        // aws endpoint 

        var endpoint = `${baseEndpoint}/googleauth/google${query}`
        // var endpoint = `http://3.110.204.201:8080/googleauth/google${query}`;
        var method = 'GET'
       
        axios({
            method: method,
            url: endpoint,
            headers: {
                'Content-Type': 'application/json',
              },
        }).then(response => {
      
            console.log(response);
            if(response.data.Token != null){
                // localStorage.setItem('stud_info', JSON.stringify(response.data));
                localStorage.setItem('token',response.data.Token);
                localStorage.setItem('userdata',JSON.stringify(response.data.user_data))
                navigate('/');
            }
        })
      }
      
      useEffect(()=>{
        console.log("useEFfect")
        console.log(localStorage.getItem('token'))
        if(localStorage.getItem('token')==null){
            console.log(userHandler());
        }
      },[]);

  }

  export default Googledata;