import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';

import { BrowserRouter,Routes, Route, RouterProvider, createBrowserRouter } from "react-router-dom";
// import Base from './components/Base';
import Menu from './components/Menu/Menu';
import Dashboard from './components/Pages/index1';
import 'bootstrap/dist/css/bootstrap.min.css';
import Mcqpage from './components/Pages/Mcqpage';
import Assignment from './components/Pages/Assignment';

import Loginpage from './components/Pages/Loginpage';
import Signup from './components/Pages/Signup';
import Googledata from './components/Pages/googledata';
import MiniDrawer from './components/Base';


// const createElements = (Comp) => {
//   return (
//     <>
//       <div style={{ width: "17%" , zIndex: 3,boxShadow:'0px 0px 10px 0px rgba(128, 128, 128, 0.5)'}}>
//         <Menu />
//       </div>
//       <div className='' style={{ width: "83%", display:'flex',flexDirection:'column', justifyContent:'space-between'}} >
//         <Header />
//         <div className='pt-3'>{Comp}</div>
//       </div>
//     </>
//   );
// };  

const createElements = (Comp) => {
  return (
    <>
      <MiniDrawer>
        <Comp/>
      </MiniDrawer>
    </>
  );
};  



const routes = createBrowserRouter([

  {
    path:'/',
    element: createElements(Dashboard)
  },
  {
    path:'/mcq',
    element: createElements(Mcqpage)
  },
  {
    path:'/assignment',
    element: createElements(Assignment)
  },
  {
    path:'/login',
    element:<Loginpage/>
  },
  {
    path:'/signup',
    element:<Signup/>
  },
  {
    path:'/googleauth/google',
    element:<Googledata/>
  }
])



function App() {
  return (
   
    <div style={{ display: "flex" }}>
    <RouterProvider router={routes} />
  </div>
  );
}

export default App;
