import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import './nav.css';

const Navabar = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.pathname);

    useEffect(() => {
        setActiveKey(location.pathname);
    }, [location]);

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey); 
    };

    return (
        <div>
            <div className="d-flex align-items-center" style={{alignItems:'center',}}>
                <Nav.Link href="/">
                    <FaChevronLeft style={{ fontSize: '30px',marginBottom:'7px', marginRight:'6px' }} />
                </Nav.Link>
                <h3>Questions</h3>
            </div>
            <Nav
                variant="underline"
                activeKey={activeKey}
                onSelect={handleSelect}
                className="d-flex flex-wrap align-items-center custom-nav"
            >
                <Nav.Item className="nvaitem">
                    <Nav.Link
                        href="/mcq/"
                        eventKey="/mcq/"
                        className={activeKey === '/mcq' || activeKey === '/mcq/' ? 'active-link' : ''}
                        >
                        MCQ
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nvaitem">
                    <Nav.Link
                        href="/assignment/"
                        eventKey="/assignment/"
                        className={activeKey === '/assignment' || activeKey === '/assignment/' ? 'active-link' : ''}
                        
                    >
                        Assignment
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nvaitem">
                    <Nav.Link
                        href="#"
                        eventKey="Youtube Links"
                        className={activeKey === 'Youtube Links' ? 'active-link' : ''}
                        disabled
                    >
                        Youtube Links
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nvaitem">
                    <Nav.Link
                        href="#"
                        eventKey="Text Documents"
                        className={activeKey === 'Text Documents' ? 'active-link' : ''}
                        disabled
                    >
                        Text Documents
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};

export default Navabar;