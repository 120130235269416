import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Loginpage.css';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Grid,Paper } from '@mui/material';
import { baseEndpoint } from '../APIs/endpoints';

const LoginPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [showPassword, setShowPassword] = useState(false);
  const [Google, setGoogleurl] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleGoogleLogin = () => {
    window.location.href = `${Google.url}`;
  };

  const userHandler = async () => {
    // var endpoint = 'http://localhost:8080/googleauth/google/login';
    var endpoint = `${baseEndpoint}/googleauth/google/login`;
    var method = 'GET';

    axios({
      method: method,
      url: endpoint,
    }).then((response) => {
      const Googledata = response.data;
      setGoogleurl(Googledata);
    });
  };

  useEffect(() => {
    userHandler();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const endpoint = 'http://localhost:8080/userauth/token';
    const endpoint =  `${baseEndpoint}/userauth/token` 
    const method = 'post';
    const sendingdata = queryString.stringify({
      username: formData.username,
      password: formData.password,
    });

    try {
      const response = await axios({
        method: method,
        url: endpoint,
        data: sendingdata,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('token_type', response.data.token_type);
      localStorage.setItem('userdetail', JSON.stringify(response.data.user));

      if (response.data.access_token) {
        navigate('/');
      }
    } catch (error) {
      if (error.response) {
        alert(
          `Error: ${error.response.data.detail || 'An error occurred. Please try again.'}`
        );
      } else if (error.request) {
        alert('No response received from server. Please try again.');
      } else {
        alert('An error occurred while setting up the request. Please try again.');
      }
      console.error('There was an error!', error);
    }
  };

  return (
    <Container fluid className="login-container" style={{ height: '100vh', background: 'white', paddingTop: '10px', fontFamily: 'sans-serif' }}>
      <Row className="align-items-center" style={{ paddingLeft: '10px' }}>
        {/* Left Section */}
        <Col md={6} className="" style={{ background: 'rgba(242, 240, 254, 1)', height: '100%', borderRadius: '10px', boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)' }}>
          <Container fluid style={{ paddingTop: '100px' }}>
            <Row className="align-items-center">
              <Col md={6} style={{ display: 'flex', position: 'relative', paddingLeft: '4%' }}>
                <div style={{ position: 'absolute', left: '34%', top: '45%', transform: 'translateY(-50%)', zIndex: '1' }}>
                  <h1 style={{ color: 'rgba(150, 82, 194, 1)', fontSize: '42px', fontWeight: '800', marginBottom: '0' }}>Content Genie</h1>
                  <p style={{ color: 'rgba(150, 82, 194, 1)', fontSize: '18px', fontWeight: '600' }}>Teachers Handbook</p>
                </div>
                <div style={{ width: '200px', height: '200px', backgroundColor: 'rgba(194, 130, 250, 0.3)', borderRadius: '50%', position: 'relative' }}></div>
              </Col>
              <img src='/images/login_image_main.svg' alt="" style={{ padding: '10%', display: 'flex', width: '90%', imageRendering: 'auto' }} />
            </Row>
          </Container>
        </Col>

        {/* Right Section */}
        <Col
    xs={12}
    md={6}
    className="login-right d-flex justify-content-center align-items-center"
    style={{ height: '100vh', fontFamily: 'sans-serif' }} // Set the height for vertical centering
>
    <div className="login-form-container" style={{ width: '100%', maxWidth: '400px' }}>
        <h2 className="login-heading" style={{ color: 'rgba(150, 82, 194, 1)', fontSize: '32px', fontFamily: 'sans-serif' }}>Login</h2>
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Form.Group controlId="formUserId" className="mb-4">
                <Form.Control
                    type="text"
                    placeholder="User Id"
                    className="input-field"
                    style={{ background: 'rgba(245, 245, 247, 1)', height: '6vh' }}
                    name="username"
                    onChange={handleChange}
                    onMouseEnter={(e) => {
                        e.target.style.borderColor = 'rgba(194, 130, 250, 1)';
                        e.target.style.background = 'white';
                        e.target.style.boxShadow = '0 0 8px rgba(194, 130, 250, 1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.borderColor = 'transparent';
                        e.target.style.background = 'rgba(245, 245, 247, 1)';
                        e.target.style.boxShadow = 'none';
                    }}
                />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-4 position-relative">
                <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    className="input-field"
                    style={{ background: 'rgba(245, 245, 247, 1)', height: '6vh' }}
                    name="password"
                    onChange={handleChange}
                    onMouseEnter={(e) => {
                        e.target.style.background = 'white';
                        e.target.style.borderColor = 'rgba(194, 130, 250, 1)';
                        e.target.style.boxShadow = '0 0 8px rgba(194, 130, 250, 1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.borderColor = 'transparent';
                        e.target.style.background = 'rgba(245, 245, 247, 1)';
                        e.target.style.boxShadow = 'none';
                    }}
                />
                <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="password-toggle-icon"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '20px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                    }}
                >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
            </Form.Group>

            <Button type="submit" className="login-button" style={{ background: 'linear-gradient(60deg, #C238CC 13.4%, #B554F2 86.6%)', border: 'none', width: '100%', height: '5vh' }}>
                Login
            </Button>
        </Form>

        <br />
        <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '500' }}>OR</div>
        <div className="google-login-container" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%' }}>
            <div
                style={{
                    width: '80%',
                    height: '4vh',
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'white',
                    color: 'black',
                    boxShadow: '0px 1px 5px black',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    alignItems: 'center'
                }}
                onClick={handleGoogleLogin}
            >
                <FcGoogle className="me-2" />
                Log in with Google
            </div>
        </div>
        <br />
        <a href="/signup" className="Register-user-link" style={{ color: 'grey', display: 'block', textAlign: 'center' }}>New user? Register here</a>
    </div>
</Col>

      </Row>
    </Container>
  );
};

export default LoginPage;
