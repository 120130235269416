import { Link } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { baseEndpoint } from '../APIs/endpoints';

const RegistrationForm = () => {

    const [Google, setGoogleurl] = useState('');
    console.log(Google.url, "itisvalu for good link");

    const handleGoogleLogin = () => {
        window.location.href = `${Google.url}`;
    };

    const userHandler = async () => {
        // var endpoint = 'http://localhost:8080/googleauth/google/login';
        var endpoint = `${baseEndpoint}/googleauth/google/login`
        var method = 'GET';

        axios({
            method: method,
            url: endpoint,
        }).then(response => {
            console.log(response);
            const Googledata = response.data;
            setGoogleurl(Googledata);
        });
    };

    useEffect(() => {
        console.log(userHandler());
    }, []);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        password: '',
        confirmPassword: '',
        organizationName: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        mobileNo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear errors when user starts typing
        if (name === 'password' || name === 'confirmPassword' || name === 'firstName' || name === 'lastName' || name === 'mobileNo' || name === 'email') {
            setErrors({
                ...errors,
                [name]: '',
            });
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleMouseEvents = (e, type) => {
        if (type === 'enter') {
            e.target.style.background = "white";
            e.target.style.borderColor = 'rgba(194, 130, 250, 1)';
            e.target.style.boxShadow = '0 0 8px rgba(194, 130, 250, 1)';
        } else if (type === 'leave') {
            e.target.style.background = "rgba(245, 245, 247, 1)";
            e.target.style.borderColor = '';
            e.target.style.boxShadow = 'none';
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {};

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            newErrors.password = 'Passwords do not match';
            newErrors.confirmPassword = 'Passwords do not match';
        }

        // Validate first name
        if (formData.firstName.length < 3) {
            newErrors.firstName = 'First name must be at least 3 characters';
        }

        // Validate last name
        if (formData.lastName.length === 0) {
            newErrors.lastName = 'Last name is required';
        }

        // Validate mobile number
        if (formData.mobileNo.length < 10 || formData.mobileNo.length > 12) {
            newErrors.mobileNo = 'Mobile number must be between 10 and 12 digits';
        }

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        setErrors({});

        // const endpoint = 'http://localhost:8080/userauth/register/';
        const endpoint = `${baseEndpoint}/userauth/register/`
        const method = 'POST';
        const sendingdata = {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            mobile_number: formData.mobileNo,
            organization_name: formData.organizationName,
            password: formData.password,
        };

        try {
            const response = await axios({
                method: method,
                url: endpoint,
                data: sendingdata,
            });
            if (response.status === 200) {
                navigate('/login');
            } else {
                console.error("Login failed with status:", response.status);
                alert("Registration failed");
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422) {
                    alert('Please enter valid information.');
                } else {
                    const errorDetail = error.response.data?.detail || 'An error occurred. Please enter valid information.';
                    alert(`Error: ${errorDetail}`);
                }
            } else if (error.request) {
                alert('No response received from server. Please try again.');
            } else {
                alert('An error occurred while setting up the request. Please try again.');
            }
            console.error('Error fetching data:', error);
        }

        console.log('Form data:', formData);
    };

    return (
        <Container style={{ display: 'flex' }}>
            <Row className="justify-content-start mt-4">
                <h4 className="text mb-0" style={{ display:'flex',alignItems:'center',fontWeight:'800'}}>Registration</h4>
                <Col xs={12} md="5">
                    <Form onSubmit={handleSubmit}>
                        <Row xs={12} style={{ display: 'flex', position: 'absolute', paddingLeft:'1px'}}>
                            <Row className='mt-0' >
                                <Col xs={12} md={5}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label>First Name :</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder="Enter your first name"
                                            required
                                            isInvalid={!!errors.firstName}
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={5}>
                                    <Form.Group controlId="formLastName">
                                        <Form.Label>Last Name :</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Enter your last name"
                                            isInvalid={!!errors.lastName}
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <br />
                                <Col xs={12} md={5}>
                                    <br />
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email address :</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Enter your email"
                                            required
                                            isInvalid={!!errors.email}
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={5}>
                                    <Form.Group controlId="formMobileNo">
                                        <br />
                                        <Form.Label>Mobile No :</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type="tel"
                                            name="mobileNo"
                                            value={formData.mobileNo}
                                            onChange={handleChange}
                                            placeholder="Enter your mobile number"
                                            required
                                            isInvalid={!!errors.mobileNo}
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.mobileNo}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={5} className='col-6'>
                                    <br />
                                    <Form.Group controlId="formPassword" className='position-relative'>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            placeholder="Password"
                                            isInvalid={!!errors.password}
                                            required
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="password-toggle-icon"
                                            style={{
                                                position: 'absolute',
                                                top: '70%',
                                                right: '20px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                zIndex: 1,
                                            }}
                                        >
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                        <Form.Control.Feedback type="invalid" style={{ position: 'absolute', top: '100%', left: '0', width: '100%' }}>
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={5}>
                                    <br />
                                    <Form.Group controlId="formConfirmPassword" className='position-relative'>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type={showPassword ? 'text' : 'password'}
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            placeholder="Confirm Password"
                                            isInvalid={!!errors.confirmPassword}
                                            required
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="password-toggle-icon"
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                right: '20px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={5} className=''>
                                    <br />
                                    <Form.Group controlId="formorganizationName">
                                        <Form.Label>Organization Name :</Form.Label>
                                        <Form.Control
                                            style={{ background: 'rgba(245, 245, 247, 1)', height: '5vh' }}
                                            type="text"
                                            name="organizationName"
                                            value={formData.organizationName}
                                            onChange={handleChange}
                                            placeholder="Organization Name"
                                            required
                                            onMouseEnter={(e) => handleMouseEvents(e, 'enter')}
                                            onMouseLeave={(e) => handleMouseEvents(e, 'leave')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Row>
                                    <Button
                                        className='col-3 col-md-3 col-xs-3 ms-md-3  mt-3'
                                        style={{
                                        background: 'linear-gradient(60deg, #C238CC 13.4%, #B554F2 86.6%)',
                                        border: '0px',
                                        marginLeft:'3%'
                                        }}
                                        variant="primary"
                                        type="submit"
                                        block
                                    >
                                        Sign Up
                                    </Button>
                                    </Row>

                            </Row>

                            <div style={{ display: 'flex', width: '900%', height: '2px', background: 'rgba(245, 245, 247, 1)', marginTop: '5%' }} />
                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '5%', background: '', height: '10vh', }}>
                                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <FcGoogle className='' onClick={handleGoogleLogin} style={{ height: '80px', width: '80px', border: '2px', boxShadow: '0px 0px 5px rgba(218, 218, 218, 1)', borderRadius: '50%', cursor: 'pointer' }} />
                                    
                                    <span onClick={handleGoogleLogin} style={{cursor: 'pointer', textAlign: 'center' }}>Sign up with </span>
                                </Row>
                                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                    <Link href="/login">Go Back to login</Link>
                                </Row>
                            </Row>
                        </Row>
                        <br />
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default RegistrationForm;
