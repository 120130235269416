import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import Leftsidemenu from "../Leftsidemenu/leftsidemenu";
import { CardContent, Typography, Grid } from "@mui/material";
import { IoMdStar } from "react-icons/io";
import { LuMoveRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import './index.css';

const Dashboard = () => {
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState(null);
    const [googleuserdata, setGoogleUserData] = useState(null);
    const [namevalue, setNameValue] = useState('');
    const [greeting, setGreeting] = useState('');
    const [hoveredCard, setHover] = useState(null);

    useEffect(() => {
        const getCurrentGreeting = () => {
            const hours = new Date().getHours();
            if (hours < 12) return 'Good Morning....';
            else if (hours < 18) return 'Good Afternoon....';
            return 'Good Evening....';
        };
        setGreeting(getCurrentGreeting());
    }, []);

    useEffect(() => {
        const storedUserDetail = localStorage.getItem('userdetail');
        if (storedUserDetail) {
            setUserDetail(JSON.parse(storedUserDetail));
            setNameValue('ok');
        } else {
            setGoogleUserData(JSON.parse(localStorage.getItem('userdata')));
        }
    }, []);

    const userName = namevalue === 'ok'
        ? `${userDetail?.first_name || ''} ${userDetail?.last_name || ''}`
        : `${googleuserdata?.first_name || ''} ${googleuserdata?.last_name || ''}`;

    const handleMouseEnter = (id) => setHover(id);
    const handleMouseLeave = () => setHover(null);
    
    const handleClick = (path) => {
        navigate(path);
    };

    const getCardStyle = (id) => ({
        cursor: 'pointer',
        border: hoveredCard === id ? '2px solid rgba(209, 117, 224, 1)' : '2px solid transparent',
        borderRadius: '10px',
        boxShadow: hoveredCard === id
        ? '0px 0px 10px 2px rgba(209, 117, 224, 0.6)'
        : '0px 2px 9px 0px rgba(128, 128, 128, 0.5)',
        transition: 'box-shadow 0.3s ease-in-out',
    });

    const cardData = [
        { title: "Questions", color: "#30A0E0", imgSrc: "/images/group1.svg", description: "List the misconceptions of a topic", path: "/mcq/" },
        { title: "Misconceptions", color: "#05A0BF", imgSrc: "/images/group2.svg", description: "List the misconceptions of a topic", path: "/" },
        { title: "Quick revision", color: "#C2528B", imgSrc: "/images/group3.svg", description: "List the misconceptions of a topic", path: "/" },
        { title: "Chapter Generator", color: "#F380B9", imgSrc: "/images/group4.svg", description: "List the misconceptions of a topic", path: "/" },
        { title: "Homework Grading", color: "#9652C2", imgSrc: "/images/group5.svg", description: "List the misconceptions of a topic", path: "/" },
        { title: "Activity Ideas", color: "#FFC973", imgSrc: "/images/group6.svg", description: "List the misconceptions of a topic", path: "/" },
    ];

    return (
        <div className="p-2 ps-3 pe-4 mt-2">
            <div className="containers">
                {/* Greeting and User Name */}
                <div className="wishbarcontainer">
                    <div className="wishbar">
                        <img className="leftTopicon" src="/images/wishbarimg/Union.svg" alt="" />
                        <img className="leftbottumicon" src="/images/wishbarimg/Union.svg" alt="" />
                        <img className="leftcircle" src="/images/wishbarimg/Torus.svg" alt="" />
                        <div className="d-flex flex-column align-items-start justify-content-center wish">
                            <p style={{fontSize:'28px',color:'white',}}>
                                {greeting}
                            </p>
                            <h1 style={{color:'white'}}>
                                {userName}
                            </h1>
                        </div>
                        <img className="rightbottumring" src="/images/wishbarimg/Ring.svg" alt="" />
                        <img className="righttopring" src="/images/wishbarimg/Torus(1).svg" alt="" />
                        <img className="righticon" src="/images/wishbarimg/Union(2).svg" alt="" />
                    </div>
                    <img className="cube" src="/images/wishbarimg/Lights1.svg" alt="" />
                </div>

                {/* Main Icons */}
                <div className="d-flex mainicon">
                    <img className="icon" src="/images/wishbarimg/Group(1).svg" alt="bagelogo" />
                </div>
            </div>

            {/* Card Layout with Left Side Menu */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={10}>
                    <Grid container spacing={2}>
                        {cardData.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    style={getCardStyle(index + 1)}
                                    className="mt-3"
                                    onMouseEnter={() => handleMouseEnter(index + 1)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(item.path)}
                                >
                                    <CardContent className="p-3 px-5">
                                        <Typography className="d-flex align-items-center justify-content-between p-1">
                                            <div className="d-flex align-items-center justify-content-center" style={{ width: '46px', height: '46px', backgroundColor: item.color, borderRadius: '50%' }}>
                                                <img src={item.imgSrc} alt={item.title} />
                                            </div>
                                            <IoMdStar className="mb-3" style={{ fontSize: '30px', color: '#ccc' }} />
                                        </Typography>
                                        <Typography className="d-flex align-items-center mt-2">
                                            <h3>{item.title}</h3>
                                            <div className="ps-3 mb-2">
                                                <LuMoveRight style={{ fontSize: hoveredCard === index + 1 ? '24px' : '20px', transition: 'font-size 0.3s ease' }} />
                                            </div>
                                        </Typography>
                                        <Typography>
                                            <p style={{ color: '#818181' }}>{item.description}</p>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                    <Leftsidemenu />
                </Grid>
            </Grid>
        </div>
    );
}

export default Dashboard;
