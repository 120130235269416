import React, { useEffect, useState, useRef, useId, useContext } from "react";
import { GrCopy } from "react-icons/gr";

import Navabar from "../Navbar/Navbar";
import { Button, Card, Form } from "react-bootstrap";
import { Autocomplete, CardMedia, Collapse, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Switch, TextField } from "@mui/material";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import jsPDF from "jspdf";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Leftsidemenu from "../Leftsidemenu/leftsidemenu";
import { MdRefresh } from "react-icons/md";
import axios from "axios";
import './Mcqpage.css'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { LuDownload } from "react-icons/lu";
import { IoRefreshOutline } from "react-icons/io5";
import { ActivityContext } from '../contexts/ActivityContext';

import { baseEndpoint } from "../APIs/endpoints";

  


const PopupBody = styled('div')(
    ({ theme }) => `
    width: max-content;
    padding: 12px 16px;
    margin: 8px;
    border-radius: 8px;
    border: 1px solid;
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    z-index: 1;
  `
  );
  
  

const Mcqpage = () => {
    const [showAnswers, setShowAnswers] = useState(false); 
    const { updateActivity } = useContext(ActivityContext);

    const handleSwitchChange = (event) => {
        setShowAnswers(event.target.checked); // Update state based on switch
    };
    const anchorRef = useRef(null);


    const [questions, setQuestions] = useState([]); 
    const [variantQuestions, setVariantQuestions] = useState([]);
    const [anchor, setAnchor] = React.useState(null);
    const options = []
    const letters = ['a', 'b', 'c', 'd'];
    const [questionsHeading, setquestionsHeading] = useState(null);
    console.log(questions,'it all seting streaming value')
    console.log(questionsHeading,'it is heading valueeeeee')
    const { selectedActivity } = useContext(ActivityContext);
    useEffect(() => {
        if (selectedActivity) {
            // Handle the selected activity's response data
            console.log(selectedActivity); // Use the data as needed
            
        }
    }, [selectedActivity]);
    const { clearSelectedActivity } = useContext(ActivityContext);
    
    const [mcqResponseData, setmcqResponseData] = useState([]);
    console.log(mcqResponseData,'mcqResponseData resquest data//////????')
    console.log(mcqResponseData.length,'whole questions data lenght ?????')
    const storeData = (id, userId, response) => {
      setmcqResponseData(prevData => ({
        ...prevData,
        [`${id}_${userId}`]: {
          id,
          response,
          userId
        }
      }));
    };
    // setQuestions(mcqResponseData.)
    const [loading, setLoading] = useState(false); 
    const [loading1, setLoading1] = useState(false); 
    const [error, setError] = useState(null);
    // switch code
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 48,
        height: 30,
        padding: 1,
        marginLeft:7,  
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              background: 'linear-gradient(60deg, rgba(194, 56, 204, 0.2) 13.4%, rgba(181, 84, 242, 0.2) 86.6%)',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 26,
          height: 26,
          background: 'linear-gradient(60deg, #C238CC 13.4%, #B554F2 86.6%)', // Gradient on thumb
          borderRadius: '50%', // Make the thumb round
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // const handleChange = (event, newValue) => {
    //     // Ensure newValue is an array
    //     if (Array.isArray(newValue)) {
    //         setSelectedOptions(newValue);
    //     }
    // };

    // const handleInputChange = (event, newInputValue) => {
    //     setInputValue(newInputValue);
    // };

    // const handleDelete = (chipToDelete) => () => {
    //     setSelectedOptions((prevOptions) =>
    //         prevOptions.filter((option) => option !== chipToDelete)
    //     );
    // };


    // chips for topic
    const [showMoreChips, setShowMoreChips] = useState(false);

    const toggleShowMoreChips = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips(!showMoreChips);
      };

      const [showMoreChips1, setShowMoreChips1] = useState(false);

    const toggleShowMoreChips1 = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips1(!showMoreChips1);
      };
    
        
    const [classValue, setClassValue] = useState('');
    const [subjectValue, setSubjectValue] = useState('');
    const [chapterValues, setChapterValues] = useState([]);
    const [topicValues, setTopicValues] = useState([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState(10); // Default value set to 10
    const [difficulty, setDifficulty] = useState('Medium'); 
    const [titleValue, setTitleValue] = useState('');
    const [classData, setClassData] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [titleOptions, setTitleOptions] = useState([]);
    const [chapterOptions, setChapterOptions] = useState([]);
    const [lastUserId, setLastUserId] = useState(null);
    const [lastId, setLastId] = useState(null);

    useEffect(() => {
      // Simulate fetching data from the backend
      fetchDataFromBackend();
    }, []);
  
    const fetchDataFromBackend = async () => {
      try {
          // const response = await fetch('http://localhost:8080/dataset/dataset_access');
          const response = await fetch(`${baseEndpoint}/dataset/dataset_access`);
          const data = await response.json();
          console.log('Fetched data:', data); // Log the data to check its structure
          setClassData(data);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

    // id for question form response
    const qid =''
    console.log(qid," iasdhiofhaspdifhosdhYBUNR EBNI")

    // Convert the array to a string if needed
    const topicString = topicValues.join(', ');

    // Convert the string back to an array for the Autocomplete component
    const topicArrayForAutocomplete = topicString.split(',').filter(Boolean);


    
    
  const handleClassChange = (event) => {
    const selectedClass = event.target.value;
    setClassValue(selectedClass);
    const subjects = classData.find((item) => item.class === selectedClass)?.subjects || [];
    setSubjectOptions(subjects);
    setSubjectValue('');
    setTitleValue('');
    setChapterValues([]);
  };

  const handleSubjectChange = (event) => {
    const selectedSubject = event.target.value;
    setSubjectValue(selectedSubject);
    const titles = subjectOptions.find((subject) => subject.subject === selectedSubject)?.titles || [];
    setTitleOptions(titles);
    setTitleValue('');
    setChapterValues([]);
  };

  const handleTitleChange = (event) => {
    const selectedTitle = event.target.value;
    setTitleValue(selectedTitle);
    const chapters = titleOptions.find((title) => title.title === selectedTitle)?.chapters || [];
    setChapterOptions(chapters);
    setChapterValues([]);
  };

    const handleChapterChange = (event) => {
      const { value } = event.target;
      setChapterValues(typeof value === 'string' ? value.split(',') : value);
    };

    const handleTopicChange = (event, newValue) => {
        setTopicValues(newValue);
      };

    const handleNumberOfQuestionsChange = (event) => {
        setNumberOfQuestions(parseInt(event.target.value));
    };

    const handleDifficultyChange = (event) => {
        setDifficulty(event.target.value);
    };
    const open = Boolean(anchor);
    const id = open ? 'popup-id' : undefined;

    const handleSubmit = async (event, retryCount = 3) => {
      event.preventDefault();
      setLoading(true);
      setError(null);
  
      clearSelectedActivity();
      setQuestions([]);
      setmcqResponseData([]); 
      const accessToken = localStorage.getItem('access_token');
      const googleToken = localStorage.getItem('token');
  
      if (!accessToken && !googleToken) {
          alert('Login to generate content');
          console.error('No access token or Google token found, redirecting to login...');
          window.location.href = '/login';
          return;
      }
  
      const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
      const user_id = userDetail?.id;
  
      // const endpoint = `http://localhost:8080/mcq/${user_id}/mcq_stream`;
      // const endpoint = `https://contentgene-backend.transpoze.ai/mcq/${user_id}/mcq_stream`;
      const endpoint = `${baseEndpoint}/mcq/${user_id}/mcq_stream`;
      const body = {
          standard: classValue,
          subject: subjectValue,
          topic: topicString,
          file_name: chapterValues,
          number: numberOfQuestions,
          complexity: difficulty,
          title: titleValue,
      };
      setquestionsHeading(body);
      let currentQuestions = [];
      let responseData = [];
  
      try {
          const response = await fetch(endpoint, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
          });
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let buffer = '';
          const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
  
          while (true) {
              const { done, value } = await reader.read();
              if (done) break;
  
              buffer += decoder.decode(value, { stream: true });
              console.log('Current buffer:', buffer);
  
              // Check for stop identifier in the buffer
              if (buffer.includes(stopIdentifier)) {
                  console.log('Stopping streaming due to identifier match.');
                  break;
              }
  
              // Process complete JSON objects in the buffer
              while (true) {
                  const startIndex = buffer.indexOf('{');
                  const endIndex = buffer.indexOf('}', startIndex) + 1;
  
                  if (startIndex === -1 || endIndex === -1) break;
  
                  const jsonString = buffer.slice(startIndex, endIndex);
                  buffer = buffer.slice(endIndex);
  
                  try {
                      const parsedData = JSON.parse(jsonString);
                      console.log(parsedData, 'Parsed data');
  
                      // Check if message is an error and should trigger a retry
                      if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && retryCount > 0) {
                          console.log('Received retry message, retrying...');
                          setLoading(true);  // Reset loading state if retrying
                          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                          await handleSubmit(event, retryCount - 1);  // Call the function again, decrementing retry count
                          return;  // Exit current function execution to avoid additional processing
                      }
  
                      // Only process valid question data
                      if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                          if (parsedData.userId) {
                              responseData.push(parsedData);
                              console.log(responseData, "All response data");
  
                              setmcqResponseData(prevQuestions => {
                                  const newQuestions = [...prevQuestions];
  
                                  // Handle question grouping
                                  if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                      newQuestions.push([parsedData]);
                                  } else {
                                      newQuestions[newQuestions.length - 1].push(parsedData);
                                  }
  
                                  return newQuestions;
                              });
                          } else {
                              currentQuestions.push(parsedData);
                              console.log(currentQuestions, 'Current questions');
  
                              setLoading(false);
                              setQuestions(prevQuestions => {
                                  const newQuestions = [...prevQuestions];
  
                                  // Check if parsedData already exists in the existing chunks
                                  const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
  
                                  // If no duplicate found, add the parsedData
                                  if (!isDuplicate) {
                                      if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= numberOfQuestions) {
                                          newQuestions.push([parsedData]);
                                      } else {
                                          newQuestions[newQuestions.length - 1].push(parsedData);
                                      }
                                  }
  
                                  return newQuestions;
                              });
                          }
  
                          // If response contains stringified JSON in `response`, parse it
                          if (parsedData.response) {
                              const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                              console.log(responseQuestions, 'Parsed response questions');
  
                              responseQuestions.forEach(question => {
                                  currentQuestions.push(question);
                              });
                          }
                      }
                  } catch (parseError) {
                      console.error('Error parsing JSON chunk:', parseError);
                      console.log('Malformed JSON string:', jsonString);
                  }
              }
          }
      } catch (err) {
          console.error('Error fetching data:', err);
          setError('An error occurred while fetching questions.');
      } finally {
          setLoading(false);
      }
  };
  


      const [visibleGroups, setVisibleGroups] = useState([false, false, false]); // Adjust based on the number of groups
      const [currentIndex, setCurrentIndex] = useState(0);

      useEffect(() => {
        const timer = setInterval(() => {
          setVisibleGroups((prev) => {
            const newVisible = [...prev];
            if (currentIndex < questions.length) {
              newVisible[currentIndex] = true;
              setCurrentIndex(currentIndex + 1);
            }
            return newVisible;
          });
        }, 2000); // Adjust timing as necessary
      
        return () => clearInterval(timer);
      }, [currentIndex]);

    const renderQuestions = () => {
      return questions.map((group, index) => (
        <div key={index}>
          {/* {mcqResponseData.length>0  ? (<h3>Variant question</h3>):(<h3>Mcq question</h3>)} */}
          <div>
            {group.map((questionObj, qIndex) => (
              <p key={qIndex}>
                {qIndex+1}.{questionObj.question}
                <button
                    onClick={(event) => handleRefreshQuestion(event, qIndex,index)}
                    style={{
                        backgroundColor: 'white',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginLeft: '10px', // Space between question and button
                    }}
                >
                    <MdRefresh style={{ borderRadius: '50%', color: '#C03DD3', cursor: 'pointer', marginBottom: '4px' }} />
                </button>
                <ul>
                  {questionObj.options.map((option, oIndex) => (
                    <li key={oIndex}>{option}</li>
                  ))}
                </ul> 
                {showAnswers && <li style={{ fontWeight: '800' }}>Answer: {questionObj.answer}</li>}
              </p>
            ))}
          </div>
          <div>
          <b onClick={() => copyToClipboard(index)} >
              <GrCopy style={{ marginLeft: '45px', opacity: mcqResponseData.length > 0 ? 1 : 0.5 }} />
          </b>
          <b onClick={() => generatePDF(questions[index], questionsHeading)}>
              <LuDownload style={{ marginLeft: '20px', opacity: mcqResponseData.length > 0 ? 1 : 0.5 }} />
          </b>
          </div>
        </div>
      ));
    };

  
  
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Simulate fetching a new question
  //     const newQuestion = {
  //       question: `${questions.question}`,
  //       options: [`Option 1`, `Option 2`, `Option 3`, `Option 4`],
  //       answer: `Option ${Math.floor(Math.random() * 4) + 1}`, // Random answer
  //       userId: 1,
  //       id: questions.length + 1,
  //     };
  //     setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  //     setLoading(false);
  //   }, 1000); // New question every second
  
  //   return () => clearInterval(interval); // Cleanup on unmount
  // }, [questions]);
  


  const handleRefreshQuestion = async (event, index, mcid) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('access_token');
    const googleToken = localStorage.getItem('token');

    if (!accessToken && !googleToken) {
        alert('Login to generate content');
        console.error('No access token or Google token found, redirecting to login...');
        window.location.href = '/login';
        return;
    }
    // const responsevalue = mcqResponseData[mcid]; // Get userId and responseId based on index
    // console.log(responsevalue,"it is the repoce value")
    // const userId = responsevalue['userId']
    // const id =  responsevalue['id']
    // console.log(userId, id, "Retrieved user ID and response ID from index");
    const item = mcqResponseData[mcid][0]; // Assuming you want the first item
    console.log(item,"it the all data to get usid rid")
    const { userId, id } = item; // Destructure userId and id

    console.log("User ID:",item.userId);
    console.log("ID:", item.id);

    // const endpoint = `http://localhost:8080/editable/users/${userId}/responses/${id}/edit`;
    const endpoint = `${baseEndpoint}/users/${userId}/responses/${id}/edit`;

    const method = 'POST';
    const sendingdata = {
        index_number: index,
    };

    try {
        const response = await axios({
            method: method,
            url: endpoint,
            data: sendingdata,
        });

        console.log(response.data, "Fetched single question data");

        

        // Check if the fetched data matches the current mcqResponseData
        if (questions[mcid]) {
          const newQuestionData = response.data.response; // This should be the new question object
      
          // Update the state to replace the specific question
          setQuestions(prevQuestions => {
              return prevQuestions.map((innerArray, innerIndex) => {
                  // Check if we are in the right inner array
                  if (innerIndex === mcid) {
                      return innerArray.map((question, questionIndex) => {
                          // Replace the specific question object at questionIndex
                          if (questionIndex === index) {
                              return { ...question, ...newQuestionData }; // Replace with new data
                          }
                          return question; // Keep the existing question
                      });
                  }
                  return innerArray; // Keep the existing inner array
              });
          });
      } else {
          console.error("ID and User ID do not match.");
      }
    } catch (error) { 
      if (error) {
        console.error('Error 504: Gateway Timeout');
        alert('Server is taking too long to respond. Please try again later.');
        } else {
        console.error('Error fetching data:', error);
        }
        console.error('Error fetching data:', error);
    } finally {
        setLoading(false); 
    }
};

const handleVariant = async (event, retryCount = 3) => {
  event.preventDefault();
  setLoading1(true);
  const accessToken = localStorage.getItem('access_token');
  const googleToken = localStorage.getItem('token'); // assuming this is where you store Google token

  if (!accessToken && !googleToken) {
      alert('Login to generate content');
      console.error('No access token or Google token found, redirecting to login...');
      // Redirect to login page or show an error message
      window.location.href = '/login';  // Replace with your login page route
      return;
  }

  const lastIndex = mcqResponseData.length - 1;
  console.log(lastIndex, "it is to variant generation");

  const item = mcqResponseData[lastIndex][0];
  const user_id = item.userId;
  const response_id = item.id;

  // const endpoint = `http://localhost:8080/mcq/users/${user_id}/responses/${response_id}/variants_stream`;
  const endpoint =  `${baseEndpoint}/mcq/users/${user_id}/responses/${response_id}/variants_stream`;
  const sendingData = {
      standard: classValue,
      subject: subjectValue,
      topic: topicString,
      file_name: chapterValues,
      number: numberOfQuestions,
      complexity: difficulty,
      title: titleValue,
  };

  let currentQuestions = [];
  let responseData = []; // Array to hold response data with userId and other info

  try {
      const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(sendingData),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = '';
      const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';

      while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          buffer += decoder.decode(value, { stream: true });
          console.log('Current buffer:', buffer);

          // Check for stop identifier in the buffer
          if (buffer.includes(stopIdentifier)) {
              console.log('Stopping streaming due to identifier match.');
              break;
          }

          // Process complete JSON objects in the buffer
          while (true) {
              const startIndex = buffer.indexOf('{');
              const endIndex = buffer.indexOf('}', startIndex) + 1;

              if (startIndex === -1 || endIndex === -1) break;

              const jsonString = buffer.slice(startIndex, endIndex);
              buffer = buffer.slice(endIndex);

              try {
                  const parsedData = JSON.parse(jsonString);
                  console.log(parsedData, 'Parsed data');

                  // Handle retry logic if the "msg" is a retry message
                  if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && retryCount > 0) {
                      console.log('Received retry message, retrying...');
                      setLoading1(true);  // Reset loading state if retrying
                      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                      await handleVariant(event, retryCount - 1);  // Call the function again, decrementing retry count
                      return;  // Exit current function execution to avoid additional processing
                  }

                  // Only process valid question data
                  if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                      if (parsedData.userId) {
                          responseData.push(parsedData);
                          console.log(responseData, "All response data");

                          setmcqResponseData(prevQuestions => {
                              const newQuestions = [...prevQuestions];

                              // Handle question grouping
                              if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                  newQuestions.push([parsedData]);
                              } else {
                                  newQuestions[newQuestions.length - 1].push(parsedData);
                              }

                              return newQuestions;
                          });
                      } else {
                          currentQuestions.push(parsedData);
                          console.log(currentQuestions, 'Current questions');

                          setLoading1(false);
                          setQuestions(prevQuestions => {
                              const newQuestions = [...prevQuestions];

                              // Check if parsedData already exists in the existing chunks
                              const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));

                              // If no duplicate found, add the parsedData
                              if (!isDuplicate) {
                                  if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= numberOfQuestions) {
                                      newQuestions.push([parsedData]);
                                  } else {
                                      newQuestions[newQuestions.length - 1].push(parsedData);
                                  }
                              }

                              return newQuestions;
                          });
                      }

                      // If response contains stringified JSON in `response`, parse it
                      if (parsedData.response) {
                          const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                          console.log(responseQuestions, 'Parsed response questions');

                          responseQuestions.forEach(question => {
                              currentQuestions.push(question);
                          });
                      }
                  }
              } catch (parseError) {
                  console.error('Error parsing JSON chunk:', parseError);
                  console.log('Malformed JSON string:', jsonString);
              }
          }
      }

  } catch (err) {
      console.error('Error fetching data:', err);
      setError('An error occurred while fetching variants.');
  } finally {
      setLoading1(false);
  }
};

    const generatePDF = (questionsData, title) => {
      const doc = new jsPDF();
      
      const centerTextWithUnderline = (text, y) => {
          const pageWidth = doc.internal.pageSize.width;
          const textWidth = doc.getStringUnitWidth(text) * doc.internal.scaleFactor;
          const x = (pageWidth - (textWidth * 2)) / 2; // Calculate x position to center text
  
          // Add the text to the document
          doc.text(text, x, y);
  
          // Draw underline
          const lineY = y + 2; // Position underline just below the text
          doc.line(x, lineY, x + textWidth * 2.3, lineY);
          doc.line(x, lineY + 1, x + textWidth * 2.3, lineY + 1);
      };
  
      const addQuestionsPage = (questions, startY, pageNumber) => {
          let y = startY;
          questions.forEach((item, index) => {
              doc.setFontSize(14);
              doc.setFont("helvetica", "bold");
  
              const questionText = (index + 1) + '. ' + item.question;
              const questionLines = doc.splitTextToSize(questionText, 150);
              const questionHeight = questionLines.length * 10;
  
              const optionsHeight = item.options.reduce((total, _, optionIndex) => {
                  const optionText = String.fromCharCode(97 + optionIndex) + ') ' + item.options[optionIndex];
                  return total + doc.splitTextToSize(optionText, 150).length * 10 + 1;
              }, 0);
  
              if (y + questionHeight + optionsHeight > 250) {
                  addPageFooter(pageNumber); 
                  doc.addPage();
                  pageNumber++;
                  y = 20; // Reset y for the new page
                  doc.setFontSize(16);
                  y += 10; // Add space after the title on the new page
              }
  
              questionLines.forEach(line => {
                  doc.text(line, 10, y);
                  y += 10;
              });
  
              doc.setFontSize(12);
              doc.setFont("helvetica", "normal");
  
              item.options.forEach((option) => {
                  const optionText = option;
                  const optionLines = doc.splitTextToSize(optionText, 190);
                  optionLines.forEach(line => {
                      doc.text(line, 10, y);
                      y += 10;
                  });
                  y += 1; // Reduced space between options
              });
          });
          return pageNumber; 
      };
  
      const addPageFooter = (pageNumber) => {
          const pageText = `Page ${pageNumber}`;
          doc.setFontSize(10);
          const pageWidth = doc.internal.pageSize.width;
          const textWidth = doc.getStringUnitWidth(pageText) * doc.internal.scaleFactor;
          const x = (pageWidth - textWidth) / 2;
          doc.text(pageText, x, 290); 
      };
  
      let y = 30; // Starting Y position
      // Document Header
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      centerTextWithUnderline(`Class ${title.standard} - ${title.subject}`, 10);
      
      const topicText = title.subtopic ? `(${title.subtopic})` : '';
      if (topicText) {
          doc.setFont("helvetica", "italic");
          const topicLines = doc.splitTextToSize(topicText, doc.internal.pageSize.width - 20); 
  
          topicLines.forEach((line, index) => {
              const topicWidth = doc.getStringUnitWidth(line) * doc.internal.scaleFactor;
              const topicX = (doc.internal.pageSize.width - topicWidth * 2.5) / 2; 
              doc.text(line, topicX, 20 + (index * 10)); // Adjust vertical position
          });
  
          doc.setFont("helvetica", "normal");
          y = 20 + (topicLines.length * 10) + 10; // Update y position
      }
  
      // Add Questions
      doc.setFontSize(16);
      doc.text('Questions and Options', 10, y);
      y += 10; // Space before the first question
      let pageNumber = 1;
      pageNumber = addQuestionsPage(questionsData, y, pageNumber); 
  
      // Add Footer for the last questions page
      addPageFooter(pageNumber); 
  
      // Add Answers
      doc.addPage();
      pageNumber++;
      doc.setFontSize(18);
      doc.text('Answers', 10, 10);
      doc.setFontSize(12);
      y = 20; // Reset y for answers page
  
      questionsData.forEach((item, index) => {
          const answerText = (index + 1) + ') ' + item.answer;
          const answerLines = doc.splitTextToSize(answerText, 190);
          answerLines.forEach((line, lineIndex) => {
              doc.text(line, 10, y + (lineIndex * 10)); // Update y correctly
          });
          y += answerLines.length * 10 + 5; // Move down after each answer
      });
  
      // Final pagination for answers page
      addPageFooter(pageNumber);
  
      // Save the document
      doc.save(`class_${title.standard}-${title.subject}.pdf`);
  };
    const contentRef = useRef(null);
  
  const copyToClipboard = (key) => {
    const selectedQuestions = questions[key]; // Access the specific inner array based on the key

    if (!selectedQuestions || selectedQuestions.length === 0) {
        alert('No questions found for this key!');
        return;
    }

    const responseData = selectedQuestions.map((item, index) => {
        const optionsList = item.options.map((option, optionIndex) => `${letters[optionIndex]}) ${option}`).join('\n');
        return `${index + 1}. ${item.question}\nOptions:\n${optionsList}\nAnswer: ${item.answer}`;
    }).join('\n\n');

    navigator.clipboard.writeText(responseData)
        .then(() => {
            alert('Response copied to clipboard!');
            console.log(key, "is the key in copy code");
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
  };
  
      
  return (
      <Container maxWidth="xlg" className="d-flex  m-0">
          <Grid container className="p-4" spacing={2}item lg={12} >
              <Grid item xs={12}>
                  <Navabar />
              </Grid>
              <Grid item xs={12} md={8} lg={10} className="d-flex flex-column">
              <form className="form-container" onSubmit={handleSubmit} encType="multipart/form-data">
              <Grid item md={12} lg={12} className="form-container-main row">

                  <Grid item xs={12} md={10} lg={10} className="grid-container col">
                      <Grid container spacing={3} className="p-2">
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth className="select-form-control no-border">
                                <InputLabel id="select-class-label">Select Class</InputLabel>
                                <Select
                                  labelId="select-class-label"
                                  id="select-class"
                                  value={classValue}
                                  onChange={handleClassChange}
                                  label="Select Class"
                                  required
                                >
                                  {classData.map((item) => (
                                    <MenuItem key={item.class} value={item.class}>
                                      {item.class}
                                    </MenuItem>
                                  ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth className="select-form-control no-border">
                                <InputLabel id="select-subject-label">Subject</InputLabel>
                                <Select
                                  labelId="select-subject-label"
                                  id="select-subject"
                                  value={subjectValue}
                                  onChange={handleSubjectChange}
                                  label="Subject"
                                  required
                                >
                                  {subjectOptions.map((subject) => (
                                    <MenuItem key={subject.subject} value={subject.subject}>
                                      {subject.subject}
                                    </MenuItem>
                                  ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth className="select-form-control no-border">
                            <InputLabel id="select-title-label">Title</InputLabel>
                            <Select
                              labelId="select-title-label"
                              id="select-title"
                              value={titleValue}
                              onChange={handleTitleChange}
                              label="Title"
                              required
                            >
                              {titleOptions.map((title) => (
                                <MenuItem key={title.title} value={title.title}>
                                  {title.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <FormControl fullWidth className="select-form-control no-border">
                                <InputLabel id="select-chapter-label">Chapter</InputLabel>
                                {/* <Select
                                  labelId="select-chapter-label"
                                  id="select-chapter"
                                  value={chapterValues}
                                  onChange={handleChapterChange}
                                  multiple
                                  label="Chapter"
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                      ))}
                                    </Box>
                                  )}
                                >
                                  {chapterOptions.map((chapter, index) => (
                                    <MenuItem key={index} value={chapter}>
                                      {chapter}
                                    </MenuItem>
                                  ))}
                                </Select> */}
                                <Select
                                    labelId="select-chapter-label"
                                    id="select-chapter"
                                    required
                                    value={chapterValues}
                                    onChange={handleChapterChange}
                                    multiple
                                    label="Chapter"
                                    renderValue={(selected) => {
                                        const visibleChips = selected.slice(0, 1);
                                        const hiddenChips = selected.slice(1);

                                        return (
                                            <>
                                                {selected.length === 0 ? (
                                                    <em>Select Chapter</em>
                                                ) : (
                                                    <Box sx={{ display: 'flex' }}>
                                                        {visibleChips.map((value, index) => (
                                                            <Chip key={index} label={value} style={{ backgroundColor: '#E9DFFC', color: '#C03DD3' }} />
                                                        ))}
                                                        <Collapse in={showMoreChips1}>
                                                            {hiddenChips.map((value, index) => (
                                                                <Chip key={index + visibleChips.length} label={value} style={{ backgroundColor: '#E9DFFC', color: '#C03DD3' }} />
                                                            ))}
                                                        </Collapse>
                                                        {hiddenChips.length > 0 && (
                                                            <b onClick={toggleShowMoreChips1} style={{ marginTop: 8, cursor: 'pointer' }}>
                                                                {showMoreChips1 ? 'show less' : `+ ${hiddenChips.length} ...`}
                                                            </b>
                                                        )}
                                                    </Box>
                                                )}
                                            </>
                                        );
                                    }}
                                >
                                {chapterOptions.map((chapter, index) => (
                                    <MenuItem key={index} value={chapter}>
                                      {chapter}
                                    </MenuItem>
                                  ))}
                            </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="p-2">
                        <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            className="select-form-control no-border"
                            multiple
                            freeSolo
                            value={topicArrayForAutocomplete}
                            onChange={handleTopicChange}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            filterSelectedOptions
                            options={[]} // No predefined options, freeSolo allows any input
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Search topic"
                                placeholder="Enter topic"
                                inputProps={{
                                    ...params.inputProps,
                                    style: { overflow: 'hidden' },
                                }}
                                />
                            )}
                            renderTags={(values, getTagProps) => {
                                const maxVisibleChips = 3; // Show only 3 chips
                                const maxCharacters = 7; // Maximum 10 characters in each chip
                                const visibleChips = values.slice(0, maxVisibleChips);
                                const hiddenChips = values.slice(maxVisibleChips);

                                return (
                                  <>
                                  {/* Step 2: Attach anchorRef to the Box */}
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    ref={anchorRef}  // Attach ref to the Box
                                  >
                                    {visibleChips.map((option, index) => {
                                      const truncatedLabel = option.length > maxCharacters
                                        ? `${option.slice(0, maxCharacters)}...`
                                        : option;
                            
                                      return (
                                        <Chip
                                          key={index}
                                          label={truncatedLabel}
                                          {...getTagProps({ index })}
                                          style={{ backgroundColor: '#E9DFFC', color: '#C03DD3' }}
                                        />
                                      );
                                    })}
                                  </Box>
                            
                                  {/* Step 3: Pass anchorRef.current as anchor */}
                                  {hiddenChips.length > 0 && (
                                    <>
                                      <b
                                        aria-describedby={id}
                                        style={{ marginTop: 8, cursor: 'pointer' }}
                                        onClick={toggleShowMoreChips}
                                      >
                                        {showMoreChips ? 'show less' : `+ ${hiddenChips.length} ...`}
                                      </b>
                                      <BasePopup
                                        id={id}
                                        open={open}
                                        anchor={anchorRef.current}  // Use ref's current value as the anchor
                                        style={{
                                          maxWidth: '50%',
                                          marginTop:'2%',
                                        }}
                                      >
                                        <PopupBody
                                          style={{
                                            background: 'white',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          {hiddenChips.map((option, index) => (
                                            <Chip
                                              key={index + visibleChips.length}
                                              label={option}
                                              {...getTagProps({ index: index + visibleChips.length })}
                                              style={{
                                                marginBottom: '8px',
                                                backgroundColor: '#E9DFFC',
                                                color: '#C03DD3',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            />
                                          ))}
                                        </PopupBody>
                                      </BasePopup>
                                    </>
                                  )}
                                </>
                                );
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth className="select-form-control no-border">
                                <InputLabel id="number-of-questions-label">No of Questions</InputLabel>
                                <Select
                                    id="number-of-questions"
                                    value={numberOfQuestions}
                                    onChange={handleNumberOfQuestionsChange}
                                    label="No of questions"
                                    required
                                >
                                    {[5, 10, 15, 20].map(num => (
                                        <MenuItem key={num} value={num}>{num}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl fullWidth className="select-form-control no-border">
                                <InputLabel id="difficulty-label">Difficulty</InputLabel>
                                <Select
                                    id="difficulty"
                                    value={difficulty}
                                    onChange={handleDifficultyChange}
                                    label="Difficulty"
                                    required
                                >
                                    <MenuItem value='Easy'>Easy</MenuItem>
                                    <MenuItem value='Medium'>Medium</MenuItem>
                                    <MenuItem value='Hard'>Hard</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={2} className="formbutton col">
                  <div className="divider"></div>

                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Button
                          type="submit"
                          className="submit-button"
                      >
                        <p style={{display:'flex'}}>
                          <img src="/images/rectangles.svg" alt="" className="pe-2" />
                          Generate</p>
                      </Button>
                  </div>
                  </Grid>
              </Grid>
              </form>
            {loading ? ( // Show loader if loading
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                            <img src="/images/Loader.gif" alt=""  style={{width:'100px'}}/>
                        </div>
                    ) : (
                <Card style={{ width:'100%',height:'564px',borderRadius:'20px'}}>
                    <div className="d-flex flex-column align-items-center justify-content-start pb-3 "  style={{width:'100%',height:'100%',}}>
                        <div className="d-flex align-items-center justify-content-start p-5 pb-2 pt-3 " style={{width:'100%'}}>
                            <div className="d-flex align-items-center "  style={{width:'60%'}}>

                                <div className="d-flex align-items-center justify-content-center" style={{background:'linear-gradient(60deg, #C238CC 13.4%, #B554F2 86.6%)', width:'30px',height:'30px', borderRadius:'30px',boxShadow:'0px 2px 5px rgba(183, 80, 237, 1)'}}>
                                    <img src="/images/rectangles.svg" alt="" srcset="" />
                                </div>
                                <p className="pt-3 ps-2">
                                    {questionsHeading
                                        ? `${questionsHeading.number} Mcq questions for class ${questionsHeading.standard} (${questionsHeading.complexity} Difficulty) ${questionsHeading.topic ? questionsHeading.topic : ''}`
                                        : ''
                                    }
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end" style={{width:'40%'}}>
                            <FormControlLabel
                            value="start"
                            control={<IOSSwitch checked={showAnswers} onChange={handleSwitchChange} />}
                            label="With Answers"
                            labelPlacement="start"  
                            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold' }, marginRight:'5px'  }} 
                            />
                            </div>
                        </div>
                        <div className="custom-scrollbar ms-5" ref={contentRef} id="pdfContent" style={{ width: '90%', height: '100%', overflowY: 'auto' }}>
                          {/* Render Selected Activity Data */}
                          {selectedActivity && selectedActivity.length > 0 ? (
                                  <div style={{ marginBottom: '40px' }}>
                                      <h5 style={{ fontWeight: '800' }}>Viewing Recent Activity Data</h5>
                                      {selectedActivity.map((item, index) => (
                                          <div key={index} style={{ marginBottom: '20px' }}>
                                              <p >{index + 1}. {item.question}</p>
                                              {item.options && item.options.length > 0 && (
                                                  <ul>
                                                      {item.options.map((option, optionIndex) => (
                                                          <li key={optionIndex}>{option}</li>
                                                      ))}
                                                  </ul>
                                              )}
                                              {showAnswers && <li style={{ fontWeight: '800' }}>Answer: {item.answer}</li>}
                                          </div>
                                      ))}
                                  </div>
                              ) : (<>
                              
                          {renderQuestions()}
                          {/* <Button
                              onClick={(event) => handleVariant(event,)}
                              size="lg"
                              className="d-flex align-items-center justify-content-evenly"
                              style={{
                                  color: '#1B2559',
                                  backgroundColor: 'white',
                                  border: "1px solid #06106f",
                                  borderRadius: '30px',
                                  width: "22%",
                                  justifyItems: 'center',
                                  marginLeft: '33%',
                                  opacity: mcqResponseData ? 1 : 0.5,
                                  pointerEvents: mcqResponseData ? 'auto' : 'none',
                              }}
                          >
                              <img src='/images/refresh.svg' style={{ width: '9%' }} />
                              Generate variant
                          </Button> */}
                          {loading1 ? (
                                // Show loader if loading1 is true
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                    <img src="/images/Loader.gif" alt="Loader" style={{ width: '100px' }} />
                                </div>
                            ) : mcqResponseData && mcqResponseData.length > 0 ? (
                                // Show the button if mcqResponseData is available and has data
                                <Button
                                    onClick={(event) => handleVariant(event)}
                                    size="lg"
                                    className="d-flex align-items-center justify-content-evenly"
                                    style={{
                                        color: '#1B2559',
                                        backgroundColor: 'white',
                                        border: "1px solid #06106f",
                                        borderRadius: '30px',
                                        width: "22%",
                                        justifyItems: 'center',
                                        marginLeft: '33%',
                                        opacity: mcqResponseData ? 1 : 0.5,
                                        pointerEvents: mcqResponseData ? 'auto' : 'none',
                                    }}
                                    disabled={!mcqResponseData || mcqResponseData.length === 0} 
                                >
                                    <img src='/images/refresh.svg' style={{ width: '9%' }} />
                                    Generate variant
                                </Button>
                            ) : (
                                ''
                            )}
                          </>
                              )}
                        </div>
                      </div>   
                </Card>)}
              </Grid>
              <Grid item xs={12} md={4} lg={2} className="recentActivity">
                  <Leftsidemenu />
              </Grid>
          </Grid>
      </Container>

  );
};

export default Mcqpage;


