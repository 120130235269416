import React, { createContext, useState } from 'react';

// Create the context
export const ActivityContext = createContext();

export const ActivityProvider = ({ children }) => {
    const [selectedActivity, setSelectedActivity] = useState([]);

    // Function to update selected activity
    const updateActivity = (newActivity) => {
        setSelectedActivity(newActivity);
    };

    // Function to clear the selected activity
    const clearSelectedActivity = () => {
        setSelectedActivity([]);
    };

    return (
        <ActivityContext.Provider value={{ selectedActivity, updateActivity, clearSelectedActivity }}>
            {children}
        </ActivityContext.Provider>
    );
};
